@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

.starrating>span
{
  font-style: normal;
  font-size: min(4.5vw,2em);
}

.starrating.modified
{
  color: pink;
}

.star-bad
{
  color: rgba(255,255,255,0.2);
  font-weight: 10;
}
.star-good
{
  color: yellow;
  font-weight: 10;
}

.starrating.no-stars>span
{
  color: rgba(128,0,0,0.7);
}

.follow-button
{
  background-color: lightgreen;
}
.follow-button.modified
{
  background-color: gray;
  pointer-events: none;
  color: lightgray;
}

.header-row
{
  background-color:  black;
  color: white;
  text-align: center;
  height: 4em;
}

.header-row img
{
  object-fit: cover;
  width: 100%;
  height: 4em;
}

.main-column
{
  background-color: #000030;
  height: calc(100vh - 5em);
  /* overflow: auto; */
}

.scrollable
{
  height: calc(100vh - 5em);
  overflow: auto;
  position: static;
}

.scroll-header
{
  height: 2em;
  background: #2195F2;
  text-align: left;
}

.scrollable-with-header
{
  height: calc(100vh - 7em);
  overflow: auto;
  padding: 0px;
  margin: 0px;
  /* width: calc(100vw - 20em); */
}

.scrollable-with-header-footer
{
  height: calc(100vh - 14em);
  overflow: auto;
  padding: 0px;
  margin: 0px;
  /* width: calc(100vw - 20em); */
}


/* .scrollable-with-header .row
{
  padding: 0px;
  margin: 0px;
} */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  box-sizing: border-box;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  overflow: hidden;
  background-color: black;
  color: white;
}
.menu-container
{
  background-color: white;
  background-image: url('/public/vertical_bg.png');
  padding-left: 0.3em;
  padding-right: 0em;
}

.container {
  display: flex;
  flex-direction: row;
}

.container h1 {
  font-size: 30px;
  text-align: center;
  color: var(--basic-color);
}
.repo-container {
  width: 50%;
  height: 700px;
  margin: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  overflow: scroll;
}
@media screen and (max-width: 600px) {
  .repo-container {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
}
.repo-text {
  font-weight: 500;
}
.repo-description {
  font-weight: 100;
  font-style: bold;
  color: var(--basic-color);
}
.list-head {
  text-align: center;
  font-weight: 400;
}
.footer {
  font-size: 15px;
  font-weight: 600;
}

ul{
  list-style-type: none;
}
 
.genre-item
{
  border: 1px solid grey;
  border-radius: 5px;
  display: inline-block;
  background-color: lightgray;
  margin: 3px;
  padding: 5px;
}

.genre-item.selected
{
  background-color: pink;
}

li.with-bullet
{
  list-style-type: disc;
  color: white;
  padding: 0.1em;
}
