.artist-cover>img{
    width: 5vw;
    height: 5vw;
    max-width: 3em;
    max-height: 3em;
}
.artist-cover
{
    padding: 1px;
    width: 5em;
    @media (max-width: 768px)
    {
        width: 2em;
    }
    /* margin: 0px; */
}
.artist-row
{
    font-size: min(3.5vw,1em);
    margin: 0px;
    margin-bottom: 6px;
    @media (max-width: 768px)
    {
        margin-bottom: 2px;
    }
 
    background-color: #000040;
}

.search-item
{
    background-color: var(--menu-background);
    color: var(--menu-foreground);
    border: 1px solid black;
    border-radius: 10px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    margin: 3px;
    display: inline-flex;
}

.search-item-prompt
{
    background-color: white;
    color: black;
}

.search-item button
{
    background-color: lightgray;
    border: 1px solid;
    font-size: small;
    border-radius: 10px;
    margin-left: 5px;
}

.search-item input
{
    background-color: white;
    border: none;
    outline: none;
}
