.loading {
    position: relative;
    color: white;
    margin: 1em;
}
.loading span {
    position: absolute;
    height: 60px;
    width: 200px;
    overflow: hidden;
}
.loading span > i {
    position: absolute;
    -webkit-animation: wait 6s reverse infinite;
    -moz-animation: wait 6s reverse infinite;
    -o-animation: wait 6s reverse infinite;
    animation: wait 6s reverse infinite;
    font-size: xx-large;
}
.loading span > i::before {
    content: '𝄞';
    font-size: xx-large;
}
.loading span > i:nth-of-type(1)::before {
    content: '𝄢';
    font-size: xx-large;
}
.loading span > i:nth-of-type(2)::before {
    content: '𝄰';
    font-size: xx-large;
}

.loading span > i:nth-of-type(1) {
    left: 6px;
    color: yellow;
}
.loading span > i:nth-of-type(2) {
    /* left: 12px; */
    margin-left: 24px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    color: lightgreen;
    font-size: large;
}
.loading span > i:nth-of-type(3) {
    left: 18px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    color: pink;
    font-size: large;
}

@-webkit-keyframes wait {
    0%   { left: 7px  }
    30%  { left: 72px  }
    60%  { left: 32px  }
    80% { left: 100px }
    100% { left: 7px }
}
@-moz-keyframes wait {
    0%   { left: 7px  }
    30%  { left: 72px  }
    60%  { left: 32px  }
    80% { left: 100px }
    100% { left: 7px }
}
@-o-keyframes wait {
    0%   { left: 7px  }
    30%  { left: 72px  }
    60%  { left: 32px  }
    80% { left: 100px }
    100% { left: 7px }
}
@keyframes wait {
    0%   { left: 7px  }
    30%  { left: 52px  }
    60%  { left: 22px  }
    80% { left: 100px }
    100% { left: 7px }
}