.circle {
    position: relative;
    border: min(0.3vw,3px) solid green;
    background-color: transparent;
    border-radius:50%;
    /* height: 100%;
    width: 100%; */
    height: 15vw;
    width: 15vw;
    /* width: 10%;
    height: 10%; */
    max-height: 150px;
    max-width: 150px;
}
.circle-text
{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    font-size: min(2vw,1.2em);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transfrom: translateY(-50%);
}

.circle-info
{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 25%;
    font-size: min(2.5vw,1.5em);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transfrom: translateY(-50%);
}

.circle-tick
{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 75%;
    font-size: min(2em,4vw);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transfrom: translateY(-50%);
}

.spotify-info
{
    font-size: min(2vw,0.9em);
}

.spotify-info td
{
    padding: 5px;
}

.welcome-list
{
    padding-left: 1em;
}