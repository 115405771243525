


div.leftbar div::hover
{
  font-size: 28px;
}

td#listarea
{
  position: absolute;
  background-color: black;
  color: white;
  margin-left: 250px;
  width: 100%;
}

.horizontal-item
{
  border: 1px solid grey;
  border-radius: 5px;
  display: inline-block;
  background-color: white;
  color: black;
  margin: 3px;
  padding: 4px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  -moz-user-select: none;
}

.horizontal-item.selected
{
  background-color: lightgreen;
}
.horizontal-item.song-length
{
  min-width: 1.8em;
  text-align: center;
}

.is-artist
{
    background-color: lightgray;
    font-style: italic;
    font-weight: bold;
}

.big-check{
  width: 18px;
  height: 18px;
}

.welcome-details
{
  font-weight:100;
  font-size: large;
  color: #b0b0b0;
}
.welcome-header
{
  font-size: x-large;
  color: #c0c0c0;
}

.album-info
{
  background-color: #000060;
}

.proposed-song
{
  background-color: #000060;

}
.proposed-song.excluded
{
  background-color: #808080;
  color: #d0d0d0;
}

.logo-header
{
  height: auto;
  width: auto;
  /* background-image: url('../../public/LTMPLogo.png'); */
}
/* .list-header
{
  height: 2vh;
  background-color: #2195F2;
  align-items: center;
  text-align: center;
} */

/* .logo-image
{
  object-fit: cover;
  width: 100%;
  height: 4em;
  background-image: url('../../public/LTMPLogo.png');
} */

.side-icon
{
  font-size: x-large;
  margin: 0px;
  line-height: 0px;
}

.side-icon::before
{
  line-height: auto;
}

div.leftbar>div>div:hover{
  background-color: #61dafb;
  cursor:grab;
}

div.leftbar>div>div.selected{
  background-color: #0000ff;
  cursor:grab;
}


div.leftbar>div>div.disabled{
  color: lightgray;
  font-style: italic;
  cursor:no-drop;
}

div.leftbar>div
{
  padding: 5px;
}

div.leftbar
{
  color: white;
  background: #2195F2;
  font-size: 16px;
  white-space: nowrap;
}
div.leftbar>div>div
{
  border: 5px solid transparent;
  text-align: left;
  line-height: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-icon
{
  color: white;
  border-radius: 50%;
  /* border: 3px solid green; */
  outline: 8vw solid black;
  background-color: #2195F2;
  outline-offset: -0.3em;
}
