.dialog-container
{
    background-color: white;
    border: none !important;
    padding: 0px;
}
.dialog-header
{
    padding: 25px;
    background-color: var(--menu-background);
    color: white
}
.dialog-prompt
{
    padding: 5px 10px 5px 10px;
    color: black;
}
.dialog-prompt input
{
    margin: 10px;
}

.dialog-buttons{
    padding: 10px;
}