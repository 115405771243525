/**,
 :after,
:before {
	box-sizing: border-box
} */

.clearfix:after,
.clearfix:before {
	content: '';
	display: table
}

.clearfix:after {
	clear: both;
	display: block
}

a.login-html {
	color: white
}

/* .login-wrap {
	width: 100%;
	margin: auto;
	max-width: 525px;
	min-height: 670px;
	position: relative;
	box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .24), 0 17px 50px 0 rgba(0, 0, 0, .19);
} */

.login-wrap{
	padding: 1em;
	/* max-width: 600px; */
}

@media (min-width:500px) {

	.login-wrap{
		width: 40em;
		/* background-color: #1161ee; */
		/* max-width: 600px; */
	}
  }

.login-html {
	/* width: 100%; */
	/* height: 100%; */
	/* position: absolute; */
	padding: 100px 0px 30px 30px;
	/* background: rgba(0, 16, 58, 0.9); */
	/* color: gray; */
}

.sign-in-htm{
	padding: 0.5em;
}

.sign-in-htm .group {
	margin-bottom: 22px;
}

.sign-in-htm .group .label,
.sign-in-htm .group .input,
.sign-in-htm .group .button {
	width: 100%;
	color: #fff;
	display: block;
}

.sign-in-htm .group .input,
.sign-in-htm .group .button {
	border: none;
	padding: 15px 20px;
	border-radius: 25px;
	background: rgba(255, 255, 255, .1);
}

.sign-in-htm .group input[data-type="password"] {
	-webkit-text-security: circle;
}

.sign-in-htm .group .label {
	color: white;
	font-size: 1.2em;
}

.login-wrap .header.red {
	color: #ff8080;
}

.login-wrap .header.green {
	color: #80ff80;
	font-weight: bold;
}

.login-form .group .button {
	background: #1161ee;
}

.foot-lnk {
	text-align: center;
}